import React from 'react'
import { styled } from 'styled-components'
import Button from '../Button/Button';
import { useTheme } from '../../context/themeContext';
import { brief, download } from '../../utils/icons';
import avatar from '../../img/my-photo.jpeg'
import shape1 from '../../img/shape1.png'
import shape2 from '../../img/shape2.png'
import { SectionLayout } from '../../styles/Layouts';
import Title from '../Title/Title';
import Progressbar from '../Progressbar/Progressbar';
import { Element } from 'react-scroll';
import resume from "../../Resume/sharath_chander_pugazhenthi.pdf";

function About() {
  const theme = useTheme();
  return (
			<Element id="about-section" name="about-section">
				<AboutStyled theme={theme}>
					<div className="about-info-con">
						<div className="about-img">
							<img src={avatar} alt="" />
						</div>
						<div className="about-content">
							<Title
								name={"About me"}
								desc1={
									"I am a passionate Software Developer with experience in building scalable, efficient, and impactful web applications and services. With a Master's in Computer Science from the University of Wisconsin and a solid foundation in front-end and back-end development, I excel in leveraging modern frameworks like Angular, React, and TypeScript for responsive design and user-centric interfaces."
								}
								desc2={
									"I have a proven track record of delivering critical projects, migrating legacy systems, and developing microservices with Python, Flask, and .NET. My experience spans designing machine learning solutions, automation frameworks, and optimizing CI/CD pipelines for over 30 projects, ensuring smooth deployments and streamlined processes."
								}
								desc3={
									"My curiosity drives me to tackle complex challenges and lead innovation. Whether it's developing data comparison tools or engineering ML-powered recommendations, I bring a deep technical understanding and leadership to every project. I thrive on collaborating across teams, embracing agile methodologies, and optimizing workflows for better efficiency. "
								}
								desc4={"Let's connect and discuss how we can innovate together."}
							/>
							<div className="progressbar-container">
								<Progressbar
									skill={"Full-Stack Development"}
									bg={theme.colorPrimary}
									progress={"95"}
								/>
								<Progressbar
									skill={"NLP & Deep Learning"}
									bg={theme.colorGreen}
									progress={"91"}
								/>
								<Progressbar
									skill={"Automated Testing"}
									bg={theme.colorPurple}
									progress={"90"}
								/>
							</div>
							<div className="btn-con">
								<a href={resume} download={"Sharath_Chander_Pugazhenthi_Resume"}>
									<Button
										name={"Download CV"}
										blob={"blob"}
										bg={theme.colorPrimary}
										color={theme.colorWhite}
										bFw={"600"}
										bRad={"30px"}
										bPad={theme.bPad1}
										icon={download}
									/>
								</a>
							</div>
						</div>
					</div>
					<img src={shape1} alt="" className="shape1" />
					<img src={shape2} alt="" className="shape2" />
				</AboutStyled>
			</Element>
		);
}

const AboutStyled = styled(SectionLayout)`
   background-color: ${(props) => props.theme.aboutBg};
   position: relative;
   overflow-y: hidden;
   overflow-x: hidden;
    .shape1{
        position: absolute;
        left: -7px;
        top: -7px;
        z-index: 1;
        opacity: 0.05;
        pointer-events: none;
    }
    .shape2{
        position: absolute;
        right: -7px;
        top: -7px;
        z-index: 1;
        opacity: 0.05;
        pointer-events: none;
    }
   .about-info-con{
        display: grid;
        grid-template-columns: 1fr 2fr;
        position: relative;
        z-index: 5;
        @media screen and (max-width: 1150px){
            grid-template-columns: repeat(1, 1fr);
        }
        .about-img{
            padding: 1.5rem;
            background-color: ${(props)=>props.theme.colorBg3};
            width: 90%;
            @media screen and (max-width: 1150px){
                width: 100%;
            }
            display: flex;
            align-items: center;

            border-radius: 5px;
            img{
                width: 100%;
                height: 50%;
            }
            
        }
        .about-content{
            .progressbar-container{
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                margin-top: 1.5rem;
            }
        }

        .btn-con{
            margin-top: 2rem;
            @media screen and (max-width: 1150px){
                    margin-bottom: 2rem;
                }
        }
   }
`;

export default About